<template>
  <div>
    <image-cropper

      v-if="selectedImage && selectedQuestion!=null"
      :image="selectedImage"
      @cropped="imageCropped"
    />
    <!-- disable animation-->
    <b-modal

      id="modal-no-animation"
      ref="options-modal"
      content-class="shadow"
      title="Soru Şıkları"
      no-fade
      size="xl"
      ok-only
      hide-footer
    >
      <b-img
        v-if="selectedQuestion!=null"
        :src="selectedQuestion.image_url"
        fluid
      />
      <hr>
      <b-form-group
        :label="$t('Soru Görseli')"
        label-for="image_url"
      >
        <b-form-file
          id="image_url"
          accept=".jpg, .jpeg"
          :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
          :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
          @change="imageSelected"
        />
        <hr>
        <b-alert
          show
          variant="success"
        >
          <div class="alert-body">
            {{ $t('Sadece 1 adet doğru cevap şıkkı seçiniz.') }}
          </div>
        </b-alert>
        <div class="d-flex align-items-center justify-content-end">

          <b-button
            v-if="selectedQuestion!=null"
            size="sm"
            variant="primary"
            @click="addNewOption()"
          >
            {{ $t('Yeni Şık Ekle') }}
          </b-button>
        </div>
        <hr>
        <div v-if="selectedQuestion!=null">
          <b-list-group-item
            v-for="(item,index) in selectedQuestion.options"

            :key="index"
            tag="li"
            :variant="item.title==''?'danger':''"
            class="d-flex justify-content-between align-items-center"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox
                  v-model="item.is_correct"
                  :value="1"
                  :unchecked-value="0"
                  name="checkbox-input"
                  @click="selectCorrection(item)"
                >
                  {{ $t('Doğru Cevap') }}
                </b-form-checkbox>

              </b-input-group-prepend>
              <b-form-input

                v-model="item.title_edit"
                :placeholder="$t('Soru Şıkkı Giriniz')"
              />
              <b-input-group-append>
                <b-form-input
                  v-model="item.point"
                  type="number"
                  :placeholder="$t('Puan')"
                />
              </b-input-group-append>
            </b-input-group>

            <b-button-toolbar>
              <b-button-group>

                <b-button
                  v-if="item.title_edit!=''"
                  variant="outline-primary"
                  @click="saveOption(item)"
                >
                  <feather-icon icon="SaveIcon" />
                </b-button>

                <b-button

                  variant="outline-primary"
                  @click="item.title_edit=item.title"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-danger"
                  @click="deleteOption(item,index)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-list-group-item>
        </div>
      </b-form-group></b-modal>
    <b-row>
      <b-col md="12">
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            {{ $t('Bu ekranda yapılan işlemler kutuckların yanındaki kaydet, sil v.b butonlara basıldığında kaydedilir. Ayrıca "Eğitim Bilgileri" sekmesine gidip kaydet butonuna basmaya gerek yoktur.') }}
          </div>
        </b-alert>
        <div class="d-flex align-items-center justify-content-end">

          <b-button
            size="sm"
            variant="primary"
            @click="addNew()"
          >
            {{ $t('Yeni Soru Ekle') }}
          </b-button>
        </div>
        <hr>
        <draggable
          v-model="questions"
          class="list-group list-group-flush cursor-move"
          tag="ul"
          @end="moveElement()"
        >
          <transition-group
            type="transition"
            name="flip-list"
          >
            <b-list-group-item
              v-for="(item,index) in questions"
              :key="index"
              tag="li"
              :variant="item.question==''?'danger':''"
              class="d-flex justify-content-between align-items-center"
            >

              <b-input-group>
                <b-input-group-prepend is-text>
                  <span>{{ index+1 }}. Soru</span>

                </b-input-group-prepend>
                <b-form-input

                  v-model="item.question_edit"
                  :placeholder="$t('Soru Giriniz')"
                />
              </b-input-group>

              <b-button-toolbar>
                <b-button-group>
                  <b-button
                    variant="outline-primary"
                    @click="showOptions(item)"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                  <b-button
                    v-if="item.question_edit!=''"
                    variant="outline-primary"
                    @click="saveData(item)"
                  >
                    <feather-icon icon="SaveIcon" />
                  </b-button>

                  <b-button

                    variant="outline-primary"
                    @click="item.question_edit=item.question"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    @click="deleteData(index,item.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </b-list-group-item>
          </transition-group>
        </draggable></b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BButtonToolbar, BButtonGroup, BModal, VBModal, BAlert, BListGroupItem, BInputGroup, BInputGroupPrepend, BFormCheckbox, BFormFile, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import imageCropper from '@core/components/image-cropper/ImageCropper.vue'

export default {
  components: {
    BRow, BCol, BFormFile, BButton, draggable, BFormInput, BButtonToolbar, BButtonGroup, BModal, BAlert, BListGroupItem, BInputGroup, BImg, BInputGroupPrepend, BFormCheckbox, imageCropper,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    trainingId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedQuestion: null,

    }
  },
  methods: {
    imageCropped(croppedImage) {
      this.selectedQuestion.image_url = croppedImage
      this.croppedImage = croppedImage
      this.saveData(this.selectedQuestion)
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null

      this.getBase64(file).then(data => { this.selectedImage = data })
    },
    showOptions(item) {
      this.selectedQuestion = item
      store.dispatch('app-training/fetchQuestionOptions', { question_id: this.selectedQuestion.id })
        .then(response => {
          const { data } = response.data
          // eslint-disable-next-line no-param-reassign
          data.forEach(val => { val.title_edit = val.title })

          this.selectedQuestion.options = data
        })
        .catch(() => {
          this.showErrorMessage()
        })
      this.$refs['options-modal'].show()
    },
    addNewOption() {
      const optionData = JSON.parse(JSON.stringify(this.blankOptionData))
      optionData.question_id = this.selectedQuestion.id

      store.dispatch('app-training/addQuestionOption', optionData)
        .then(response => {
          const { data } = response.data
          data.title_edit = data.title
          this.selectedQuestion.options.push(data)
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    saveOption(item) {
      const formData = JSON.parse(JSON.stringify(item))
      formData.title = formData.title_edit
      store.dispatch('app-training/updateQuestionOption', formData)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          item.title = item.title_edit

          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    deleteOption(index, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Şıkkı Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-training/deleteQuestionOption', { id })
            .then(() => {
              this.selectedQuestion.options.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    addNew() {
      const questionData = JSON.parse(JSON.stringify(this.blankQuestionData))

      store.dispatch('app-training/addQuestion', questionData)
        .then(response => {
          const { data } = response.data
          data.options = []
          data.question_edit = data.question
          this.questions.push(data)
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    saveData(item) {
      const formData = JSON.parse(JSON.stringify(item))
      if (this.croppedImage != null) {
        formData.upload_image = this.croppedImage
      }
      formData.question = formData.question_edit
      store.dispatch('app-training/updateQuestion', formData)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          item.question = item.question_edit

          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(index, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Soruyu Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-training/deleteQuestion', { id })
            .then(() => {
              this.questions.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    moveElement() {
      const newOrderingData = []
      this.questions.forEach(val => {
        newOrderingData.push({ id: val.id })
      })
      store.dispatch('app-training/changeQuestionOrdering', { ordering_data: newOrderingData })
        .then(() => {
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup(props) {
    const blankQuestionData = {
      question: '',
      point: 0,
      training_id: props.trainingId,
      options: [],
      image_url: '',

    }
    const blankOptionData = {
      title: '',
      question_id: 0,
      is_correct: false,

    }
    const croppedImage = ref(null)
    const selectedImage = ref(null)
    const questions = ref([])

    store.dispatch('app-training/fetchQuestions', { training_id: props.trainingId })
      .then(response => {
        const { data } = response.data
        data.forEach(val => {
          // eslint-disable-next-line no-param-reassign
          val.question_edit = val.question
          // eslint-disable-next-line no-param-reassign
          val.options = []
        })
        questions.value = data
      })
      .catch(() => {
        this.showErrorMessage()
      })
    const onSubmit = () => {

    }

    // ? dataField is field name of `userDataSocial` object

    return {
      questions,
      onSubmit,
      croppedImage,
      selectedImage,
      blankQuestionData,
      blankOptionData,
    }
  },
}
</script>

<style>

</style>
