<template>
  <div>
    <!-- basic modal -->
    <b-modal
      id="preview-modal"
      title="Eğitim Önizlemesi"
      size="xl"
      ok-only
      ok-title="Accept"
    >
      <training-preview :data="trainingData" />
    </b-modal>
    <b-row>
      <b-col md="12">
        <!-- BODY -->
        <image-cropper

          v-if="selectedImage"
          :image="selectedImage"
          :ratio="800/550"
          @cropped="imageCropped"
        />
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            v-if="trainingData"
            class="p-2"
            @submit.prevent="handleSubmit(saveData)"
            @reset.prevent="resetForm"
          >

            <b-img
              v-if="trainingData!=null"
              :src="trainingData.image_url"
              fluid
            />
            <hr>
            <b-form-group
              :label="$t('Eğitim Görseli')"
              label-for="image_url"
            >
              <b-form-file
                id="image_url"
                accept=".jpg, .jpeg"
                :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
                :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
                @change="imageSelected"
              />
            </b-form-group>
            <hr>
            <b-form-group
              v-if="categoryData"
              :label="$t('Eğitim Kategorisi')"
              label-for="category_id"
            >
              <v-select
                id="category_id"
                v-model="trainingData.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryData"
                :reduce="val => val.value"
                :clearable="false"
                input-id="category_id"
              />
            </b-form-group>

            <!-- Firma ad -->
            <validation-provider
              #default="validationContext"
              :name="$t('Eğitim Adı')"
              rules="required|min:2"
            >
              <b-form-group
                :label="$t('Eğitim Adı')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="trainingData.name"
                  autofocus
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Firma ad -->
            <validation-provider
              #default="validationContext"
              :name="$t('Açıklama')"
              rules=""
            >
              <b-form-group
                :label="$t('Açıklama')"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="trainingData.description"
                  placeholder="Textarea"
                  :state="getValidationState(validationContext)"
                  rows="3"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group
              v-if="usersData"
              :label="$t('Eğitimi Alması Gereken Kullanıcılar')"
              label-for="users"
            >
              <b-row>
                <b-col md="9">
                  <v-select
                    id="users"
                    v-model="trainingData.users"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    :options="usersData"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="users"
                  />
                </b-col>
                <b-col md="3">

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="selectAllUsers(true)"
                  >
                    <feather-icon
                      icon="HomeIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Tümünü Seç') }}</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="selectAllUsers(false)"
                  >
                    <feather-icon
                      icon="HomeIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Seçimleri Kaldır') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              v-if="trainingData"
              :label="$t('Başlangıç Tarihi')"
              label-for="user_id"
            >
              <b-form-datepicker
                id="start_date"
                v-model="trainingData.start_date"
                class="mb-1"
              />
            </b-form-group>
            <b-form-group
              v-if="trainingData"
              :label="$t('Bitiş Tarihi')"
              label-for="user_id"
            >
              <b-form-datepicker
                id="end_date"
                v-model="trainingData.end_date"
                class="mb-1"
              />
            </b-form-group>
            <b-form-group
              v-if="trainingData"
              :label="$t('Sınav Süresi (Dakika)')"
              label-for="time"
            >
              <b-form-input
                id="time"
                v-model="trainingData.time"
                autofocus
                type="number"
                autocomplete="off"
              />
            </b-form-group>
            <b-form-group
              v-if="trainingData"
              :label="$t('Gerekli Başarı Oranı (Yüzde cinsinden)')"
              label-for="required_success_rate"
            >
              <b-form-input
                id="required_success_rate"
                v-model="trainingData.required_success_rate"
                autofocus
                max="100"
                min="0"
                type="number"
                autocomplete="off"
              />
            </b-form-group>
            <b-form-group
              v-if="trainingData"
              :label="$t('Eğitim testini başarısız tamamlayan kişiler sonraki teste kaç saat sonra girebilsin')"
              label-for="next_test_waiting_hours"
            >
              <b-row>
                <b-col md="12">
                  <b-button-group>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      @click="trainingData.validity_days=5*24"
                    >
                      {{ $t('5 Gün') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      @click="trainingData.validity_days=10*24"
                    >
                      {{ $t('10 Gün') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      @click="trainingData.validity_days=15*24"
                    >
                      {{ $t('15 Gün') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      @click="trainingData.validity_days=20*24"
                    >
                      {{ $t('20 Gün') }}
                    </b-button>
                  </b-button-group>
                </b-col>
                <b-col md="12">
                  <b-form-input
                    id="next_test_waiting_hours"
                    v-model="trainingData.next_test_waiting_hours"
                    autofocus
                    max="1000"
                    min="0"
                    type="number"
                    autocomplete="off"
                  />
                </b-col>
              </b-row></b-form-group>
            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <span>Teste katılanların başarılı sayılabilmesi için girilen soruların toplam puanının yukarıdaki başarı oranı verisi kadar (yüzde cinsinde) doğru yanıt yapması gerekmektedir. </span>
              </div>
            </b-alert>
            <b-form-group v-if="trainingData.video_url!=''">
              <b-embed
                type="video"
                aspect="16by9"
                :src="trainingData.video_url"
                allowfullscreen
              />
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-2"
                  type="submit"
                  @click="deleteVideo"
                >
                  {{ $t('Videoyu Sil') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-clipboard:copy="trainingData.video_url"
                  v-clipboard:success="onCopy"
                  variant="secondary"
                  class="mr-2"
                >
                  {{ $t('Video Adresini Kopyala') }}
                </b-button>
              </div>
            </b-form-group>
            <div
              v-if="uploadIsProgress"
              class="progress-wrapper"
            >
              <b-card-text class="mb-0">
                {{ $t('Dosya Yükleniyor. Lütfen Bekleyiniz...') }} {{ uploadProgress+'%' }}
              </b-card-text>
              <b-progress
                v-model="uploadProgress"
                max="100"
              />
            </div>
            <b-form-group
              :label="$t('Eğitim Videosu')"
              label-for="video_url"
            >
              <b-form-file
                id="video_url"
                :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
                :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
                @change="uploadVideo"
              />
            </b-form-group>
            <b-form-group
              :label="$t('İçerik')"
              label-for="description"
            >

              <VueEditor
                v-model="trainingData.content"
                :editor-options="editorSettings"
                use-custom-image-handler
                @image-added="handleImageAdded"
              />
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Kaydet') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.preview-modal
                variant="secondary"
                class="mr-2"
              >
                {{ $t('Önizleme') }}
              </b-button>
            </div>
          </b-form>

        </validation-observer>
      </b-col>

    </b-row>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BCol, BButton, BFormTextarea, BCardText, BProgress, BFormDatepicker, BAlert, BModal, VBModal, BEmbed, BButtonGroup, BImg, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import { VueEditor, Quill } from 'vue2-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
import Compressor from 'compressorjs'
import imageCropper from '@core/components/image-cropper/ImageCropper.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import trainingPreview from './TrainingPreview.vue'

Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
export default {
  components: {
    BForm,
    BAlert,
    BRow,
    BCol,
    BEmbed,
    BCardText,
    BProgress,
    BModal,
    imageCropper,
    BButtonGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueEditor,
    BImg,
    BFormFile,
    vSelect,
    trainingPreview,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditTrainingSidebarActive',
    event: 'update:is-edit-training-sidebar-active',
  },
  props: {
    blankTrainingData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      uploadProgress: 0,
      filesOverlay: false,
      uploadIsProgress: false,
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: true,
        },
      },

    }
  },
  watch: {
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Video Adresi Kopyalandı',
          icon: 'BellIcon',
        },
      })
    },
    deleteVideo() {
      store.dispatch('app-training/deleteVideo', { training_id: this.trainingData.id, video_name: this.trainingData.video_name })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.trainingData.video_url = ''
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    imageCropped(croppedImage) {
      this.trainingData.image_url = croppedImage
      this.croppedImage = croppedImage
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null

      this.getBase64(file).then(data => { this.selectedImage = data })
    },
    uploadVideo(event) {
      // eslint-disable-next-line no-unused-vars
      const file = event.target.files ? event.target.files[0] : null

      const formData = new FormData()
      this.uploadIsProgress = true
      this.uploadProgress = 0

      formData.append('training_id', this.trainingData.id)
      formData.append('file', file)
      const config = {
        onUploadProgress: function (progressEvent) {
          this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }.bind(this),
      }
      axios
        .post('/api/training-upload-video', formData, config)
        .then(response => {
          this.$bvToast.toast(this.$t('Yükleme İşlemi Tamamlandı'), {
            title: this.$t('İşlem Başarılı'),
            variant: 'success',
            solid: false,
          })

          this.trainingData.video_url = response.data.data.video_url
        })
        .catch(() => {
          this.showErrorMessage()
        })
        .finally(() => {
          this.uploadIsProgress = false
          this.uploadProgress = 0
        })
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // eslint-disable-next-line no-new

      const { showErrorMessage } = this
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.6,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          const formData = new FormData()
          const fileData = new File([result], file.name, { type: result.type })

          formData.append('file', fileData)
          axios
            .post('/api/add-editor-files', formData)
            .then(response => {
              Editor.insertEmbed(cursorLocation, 'image', response.data.data)
              resetUploader()
            })
            .catch(() => {
              showErrorMessage()
            })
            .finally(() => {

            })
        },
        error(err) {
          alert(err)
        },
      })
    },
    selectAllUsers(select = false) {
      this.trainingData.users = []
      if (select) {
        this.usersData.forEach(val => {
          this.trainingData.users.push(val.value)
        })
      }
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    makeTrainingData() {
      this.trainingData = JSON.parse(JSON.stringify(this.blankTrainingData))
    },
    saveData() {
      this.trainingData.upload_image = this.croppedImage
      store.dispatch('app-training/updateTraining', this.trainingData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    // fetch user
    const trainingData = ref(null)
    const usersData = ref(null)
    const croppedImage = ref(null)
    const selectedImage = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    const categoryData = ref(null)
    store
      .dispatch('app-training/fetchTrainingCategories', {
        list_type: 'all',
      })
      .then(response => {
        const { data } = response.data
        const arr = []
        data.forEach(val => {
          arr.push({ label: `${val.name}`, value: val.id })
        })

        categoryData.value = arr
      })
      .catch(() => {
      })

    const onSubmit = () => {

    }
    const resettrainingData = () => {
      trainingData.value = JSON.parse(JSON.stringify(props.blankTrainingData))
    }
    resettrainingData()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resettrainingData)

    return {
      onSubmit,
      croppedImage,
      selectedImage,
      refFormObserver,
      getValidationState,
      trainingData,
      usersData,
      categoryData,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
