<template>
  <content-with-sidebar
    v-if="data"
    class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card

            :title="data.name"
          >

            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content"
              v-html="data.content"
            />

            <!-- eslint-enable -->
            <hr class="my-2">

          </b-card>
        </b-col>
        <!--/ blogs -->

        <!-- Leave a Blog Comment -->

        <!--/ Leave a Blog Comment -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

  </content-with-sidebar>
</template>

<script>
import {

  BCard, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
